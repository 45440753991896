import React from "react";
import { getNewsDetail } from "services";
import { BaseLayout, Loading } from "components";
import styles from "./styles.module.scss";
import { isNative, nativeHref, getLocalTime } from 'utils';
import { linkConfig } from 'config';

class GuideDetail extends React.PureComponent {
  state = {
    loading: true,
    isShow: false,
  };
  
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    const { search } = history.location
    if (search) {
      const params = {};
      search.replace(/([^?&=]+)=([^&]+)/g, function(_, k, v) {
        params[k] = v;
      });
      if (params.type === linkConfig.strategy) {
        this.setState({isShow: true})
      }
    }
    if (!params.id || !Number.isInteger(parseInt(params.id))) {
      history.goBack();
    }
    this.getDetail();
  }
  
  getDetail = async () => {
    const { match } = this.props;
    const { params } = match;
    const res = await getNewsDetail(params.id);
    if (res.success) {
      this.setState({
        loading: false,
        ...res.ch_msg[0]
      });
    }
  };

  // 返回
  onBack = () => {
    window.history.back();
  }

  handleClick = () => {
    nativeHref('toQutation', { chartId: this.state.author });
  }


  render() {
    const { loading, title, content, isShow, lctime, author } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
            <section className={styles.detail_new}>

              {
                isNative ? null :
                  <header className={styles.header}>
                    <div className={styles.back} onClick={this.onBack} />
                    <div className={styles.title}>资讯详情</div>
                  </header>
              }
              <div className={styles.content} >
                <div className={styles.title}>{title}</div>
                <div className={styles.time}>{lctime && getLocalTime(lctime)}</div>

                <p className={styles.msg} dangerouslySetInnerHTML={{ __html: content }}></p>
                {
                  (isShow && author) &&
                  <div className={`${styles.iKonw}`} onClick={this.handleClick}>
                    去查看
                  </div>
                }
                </div>
            </section>
          )}
      </BaseLayout>
    );
  }
}
export default GuideDetail;
